import React from 'react';

// UI
import { CircularProgress, makeStyles, Typography } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  loader: {
    marginTop: "20px",
  }
}));

function CenteredLoader({ title }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress className={classes.loader} />
      {title && 
        <Typography variant="h6">
          {title} 
        </Typography>
      }
    </div>
  );
}

export default CenteredLoader;
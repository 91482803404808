import React from "react";
import { MTS_MTO, PLANTS } from "../../../utils/constants";

export const COLUMNS = [
  {
    title: "Codice riferimento",
    field: "cod_riferimento",
    cellStyle: { padding: 8 },
    render: (rowData) => (
      <>
        <small>{rowData.cod_riferimento}</small>
      </>
    ),
  },
  {
    title: "Codice materiale",
    field: "cod_materiale",
    cellStyle: { padding: 8 },
    render: (rowData) => (
      <>
        <small>
          <b>{rowData.cod_materiale}</b>
        </small>
      </>
    ),
  },
  {
    title: "Descrizione",
    field: "des_materiale",
    cellStyle: { padding: 8, lineHeight: 1.2, minWidth: 200 },
    headerStyle: { minWidth: 200 },
    render: (rowData) => (
      <>
        <small>{rowData.des_materiale}</small>
      </>
    ),
  },
  {
    title: "Stato",
    field: "cod_stato_materiale",
    cellStyle: { padding: 8 },
  },
  {
    title: "Stock",
    field: "flg_stock",
    type: "boolean",
    cellStyle: { padding: 8 },
  },
  {
    title: "Pianificato Produzione",
    field: "flg_pianificato_produzione",
    type: "boolean",
    cellStyle: { padding: 8 },
  },
  {
    title: "Ordini",
    field: "flg_ordini",
    type: "boolean",
    cellStyle: { padding: 8 },
  },
  {
    title: "MTS/MTO",
    lookup: MTS_MTO.reduce((o, i) => {
      return { ...o, [i]: i };
    }, {}),
    field: "stock_order",
    cellStyle: { padding: 8 },
  },
  {
    title: "Stabilimento",
    field: "stabilimento",
    lookup: PLANTS.reduce((o, i) => {
      return { ...o, [i]: i };
    }, {}),
    cellStyle: { padding: 8 },
  },
];

export const OPTIONS = {
  filtering: true,
  pageSize: 10,
  headerStyle: { padding: 12 },
  filterCellStyle: {
    padding: 8,
  },
};

export const LOCALIZATION = {
  header: {
    actions: "",
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count} materiali",
    labelRowsSelect: "righe",
  },
};

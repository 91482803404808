import React, {useState} from "react";

// UI
import MaterialTable from 'material-table';
import _ from "lodash";

// Table Params
import {COLUMNS, OPTIONS, LOCALIZATION} from "./Params/MaterialRegistryAssign"
import {tableIcons} from "./Params/Icons";


function MaterialRegistryAssignTable({ data, openAssignMaterial }) {

  const _data = data && data.filter(i => i.cod_riferimento === null)
  const [selectedCodes, setSelectedCodes] = useState([])

  const handleSelection = (rows) => {
    setSelectedCodes(rows.map(i => i.cod_materiale))
  }

  const applyAssignment = () => {
    openAssignMaterial(selectedCodes)
  }

  return (
    <>
      <MaterialTable
        columns={COLUMNS}
        data={_data}
        icons={tableIcons}
        isLoading={_data === undefined}
        options={OPTIONS}
        localization={LOCALIZATION}
        title={"Da assegnare"}
        onSelectionChange={(rows) => handleSelection(rows)}
        actions={[
          {
            icon: tableIcons.Key,
            tooltip: 'Assegna come riferimento',
            position: "row",
            onClick: (event, rowData) => openAssignMaterial([rowData.cod_materiale], undefined, true)
          },
          {
            icon: tableIcons.Link,
            tooltip: "Assegna",
            position: "toolbarOnSelect",
            onClick: () => {applyAssignment()}
          },
        ]}
      />
    </>
  );
}

export default React.memo(MaterialRegistryAssignTable, (props, nextProps) => {
  // Render component only when users changes
  return _.isEqual(props.data, nextProps.data);

})

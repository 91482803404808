import React, { useState } from "react";
import ApiService from "../../services/ApiService";

// UI
import {Button, Dialog, DialogContent, DialogTitle, Grid,} from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CenteredLoader from "../Loading/CenteredLoader";


const useStyle = makeStyles(theme => ({
    button: {
      margin: theme.spacing(3, 0, 2),
    }
}))


function CloseRevisionDialog({open, toggleOpen, openAlert, counter, setTableData}) {

  const classes = useStyle();
  const [loading, setLoading] = useState(false);


  const closeService = (outcome, toPrint) => {
    console.log(toPrint);
    setLoading(false);
    toggleOpen();
    const text =
      outcome === "success"
        ? "Operazione avvenuta con successo"
        : "Impossibile completare l'operazione";
    openAlert(outcome, text);
    if (outcome === 'success') {
      setTableData(null)
    }
  };


  const closeEntireProcess = () => {
    setLoading(true);
    ApiService.getApi(`make-or-buy/close`)
      .then((msg) => {closeService("success", msg)})
      .catch((err) => {closeService("error", err)});
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={toggleOpen}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Chiusura pianificazione</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={2}>
              <ErrorOutlineIcon style={{ marginTop: 20 }} color="primary" />
            </Grid>
            <Grid item xs={10}>
              <p style={{fontSize: 15}}>
                Confermi di voler chiudere la pianificazione per il periodo di riferimento? Tutti i codici saranno approvati
              </p>
              <p style={{fontSize: 16}}>
                Sono stati revisionati <b>{counter.done}</b> su <b>{counter.total}</b>
              </p>
            </Grid>
          </Grid>
          {loading && <CenteredLoader title={""} />}
          <Grid container>
            <Grid item xs={5}>
              <Button onClick={toggleOpen} variant="contained" className={classes.button}>
                Esci
              </Button>
            </Grid>
            <Grid item xs={7} style={{textAlign: "end"}}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={closeEntireProcess}
              >
                Confermo
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CloseRevisionDialog;

import React, { useState, useEffect } from "react";
import _ from "lodash";

import { Button, LinearProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import SimpleAlert from "../../components/Alert/SimpleAlert";
import CloseRevisionDialog from "../../components/Dialogs/CloseRevisionDialog";
import ApiService from "../../services/ApiService";
import MakeOrBuyTable from "../../components/Tables/MakeOrBuyTable";
import DemandRevisionChart from "../../components/Charts/DemandRevisionChart";
import PlantDemandBarChart from "../../components/Charts/PlantDemandBarChart";
import { COLORS, FILTER_DIMENSION } from "../../utils/constants";
import { DoneAll } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import RevisionClosed from "../../components/Alert/RevisionClosed";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: "center",
    padding: 5,
  },
  grid: {
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

export default function MakeOrBuy() {
  const classes = useStyles();
  const [idx, setIdx] = useState(null);
  const [period, setPeriod] = useState("");
  const [loading, setLoading] = useState(false);
  const [graphLoading, setGraphLoading] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [filterDimension, setFilterDimension] = useState();
  const [filterValue, setFilterValue] = useState();
  const [filteredCodes, setFilteredCodes] = useState(null);
  const [weekColumns, setWeekColumns] = useState(null);
  const [plantChartData, setPlantChartData] = useState(null);
  const [demandChartData, setDemandChartData] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [alert, setAlert] = useState({ open: false, type: "", message: "" });
  const [counter, setCounter] = useState({ total: 0, done: 0 });
  const [revisionClosed, setRevisionClosed] = useState(false);

  const toggleConfirmDialog = () => setShowConfirmDialog(!showConfirmDialog);

  const openAlert = (type, message) => {
    setAlert({ open: true, type, message });
  };
  const toggleAlert = () => {
    setAlert({ ...alert, open: !alert.open });
  };

  const fetchTableData = () => {
    console.log(`fetching data...`);
    setLoading(true);
    ApiService.getApi("make-or-buy/table")
      .then((result) => {
        setTableData(_.keyBy(result["table_data"], "uuid"));
        setFilteredCodes(result["table_data"]);
        setWeekColumns(result["week_columns"]);
        setPeriod(result["period"]);
        if (!result["week_columns"].length) {
          setRevisionClosed(true);
        }
      })
      .catch((err) => {
        console.error(`Error when fetching make or buy table data`, err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchPlantChartData = () => {
    console.log(`fetching plant chart data...`);
    setLoading(true);
    ApiService.getApi("make-or-buy/plant")
      .then((result) => {
        setPlantChartData(result);
      })
      .catch((err) => {
        console.error(`Error when fetching make or buy plant chart data`, err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchDemandChartData = (idx) => {
    console.log(`fetching data for one code...`);
    setGraphLoading(true);
    ApiService.getApi(`demand_revision/${idx}`)
      .then((result) => {
        setDemandChartData(result);
        setGraphLoading(false);
      })
      .catch((err) => {
        console.error(`Error when fetching data`, err);
        setGraphLoading(false);
      });
  };

  const handleChangedData = (event, rowData) => {
    const _uuid = rowData.uuid;
    setIdx((prevData) => ({ ...prevData, _uuid }._uuid));
  };

  useEffect(() => {
    if (!plantChartData) {
      fetchPlantChartData();
    }
  }, [plantChartData]);

  useEffect(() => {
    console.log("useEffect");
    if (!tableData) {
      fetchTableData();
    } else {
      if (filterValue) {
        setFilteredCodes(
          Object.values(tableData).filter(
            (i) => i[filterDimension.value] === filterValue
          )
        );
      } else {
        setFilteredCodes(Object.values(tableData));
      }
      setCounter({
        total: Object.values(tableData).length,
        done: Object.values(tableData).filter((i) => i["flg_revisionato"])
          .length,
      });
    }
  }, [tableData]);

  useEffect(() => {
    if (idx !== null) {
      fetchDemandChartData(idx);
    }
  }, [idx]);

  function onDimensionChange(event, category) {
    setFilterDimension(category);
    setFilterValue(undefined);
  }

  function onValueChange(event, value) {
    setFilterValue(value);
    if (value) {
      setFilteredCodes(
        Object.values(tableData).filter(
          (i) => i[filterDimension.value] === value
        )
      );
    } else {
      setFilteredCodes(Object.values(tableData));
    }
  }

  const Title = () => (
    <Grid container style={{ marginBottom: "10px" }}>
      <Grid item xs={4}>
        <Typography variant="h5">Make or Buy</Typography>
      </Grid>
      <Grid item xs={4}>
        <Grid container justify="center">
          {period && (
            <Typography variant="h6">
              Periodo di riferimento: <b>{period}</b>
            </Typography>
          )}
        </Grid>
      </Grid>
      {!revisionClosed && tableData && (
        <Grid item xs={4}>
          <Grid container justify="flex-end">
            <Button
              variant="contained"
              color="primary"
              startIcon={<DoneAll />}
              onClick={toggleConfirmDialog}
            >
              Chiudi revisione
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );

  return (
    <>
      <Title />
      <LinearProgress
        style={{
          visibility:
            loading || (!filteredCodes && !revisionClosed)
              ? "visible"
              : "hidden",
        }}
      />
      {!revisionClosed && tableData && (
        <div className={classes.root}>
          <Grid container spacing={3}>
            {plantChartData &&
              ["flumeri", "giano", "outsourcing"].map((plant) => (
                <Grid item xs={4}>
                  <Paper className={classes.paper}>
                    <p
                      style={{
                        color: COLORS[plant],
                        textTransform: "capitalize",
                        fontWeight: "bold",
                        margin: 2,
                      }}
                    >
                      {plant}
                    </p>
                    <PlantDemandBarChart
                      id={plant}
                      data={plantChartData[plant]}
                      plant={plant}
                    />
                  </Paper>
                </Grid>
              ))}
            <Paper
              style={{ marginLeft: "auto", marginRight: "auto", marginTop: 10 }}
            >
              <Grid container>
                <Grid item className={classes.grid}>
                  <Autocomplete
                    id="dimension"
                    style={{ width: 250, margin: 12 }}
                    value={filterDimension || null}
                    options={FILTER_DIMENSION}
                    getOptionLabel={(i) => i.label}
                    renderOption={(i) => i.label}
                    onChange={onDimensionChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Filtra per:"
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid item className={classes.grid}>
                  <Autocomplete
                    id="value"
                    style={{ width: 300, margin: 12 }}
                    options={
                      filterDimension
                        ? _.sortBy(_.uniq(
                            Object.values(tableData).map(
                              (i) => i[filterDimension.value]
                            )
                          ))
                        : []
                    }
                    value={filterValue || null}
                    onChange={onValueChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        label={filterDimension ? filterDimension.label : "-"}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Paper>
            <Grid item xs={12}>
              <MakeOrBuyTable
                filteredCodes={filteredCodes}
                tableData={tableData}
                setTableData={setTableData}
                setPlantChartData={setPlantChartData}
                setIdx={setIdx}
                weekColumns={weekColumns}
                openAlert={openAlert}
                handleChangeData={handleChangedData}
                setLoading={setLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <LinearProgress
                style={{ visibility: graphLoading ? "visible" : "hidden" }}
              />
              <Paper style={{ padding: 10 }}>
                {demandChartData && idx && (
                  <>
                    <Grid container style={{ marginBottom: 10 }}>
                      <Grid item xs={7}>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            style={{
                              marginLeft: 10,
                              marginTop: 10,
                            }}
                          >
                            <b>{`${tableData[idx]["cod_riferimento"]} - ${tableData[idx]["des_materiale"]}`}</b>
                          </Grid>
                          <Grid item style={{ margin: 10 }}>
                            <b>
                              <small>Formato: </small>
                            </b>{" "}
                            {tableData[idx]["tipo_lavorazione"]}
                            <br />
                            <b>
                              <small>Trafila: </small>
                            </b>{" "}
                            {tableData[idx]["cod_trafila"]}
                          </Grid>
                          <Grid item style={{ margin: 10 }}>
                            <b>
                              <small>Stabilimento: </small>
                            </b>{" "}
                            {tableData[idx]["stabilimento"]}
                            <br />
                            <b>
                              <small>Materia prima: </small>
                            </b>{" "}
                            {tableData[idx]["des_materia_prima"]}
                          </Grid>
                          <Grid item style={{ margin: 10 }}>
                            <b>
                              <small>MTS/MTO: </small>
                            </b>{" "}
                            {tableData[idx]["stock_order"]}
                            <br />
                            <b>
                              <small>Profit Center: </small>
                            </b>{" "}
                            {tableData[idx]["des_profit_center"]}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={5}>
                        {tableData[idx]["note_commerciali"] && (
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              style={{ marginLeft: 10, marginTop: 10 }}
                            >
                              <b>Note commerciali:</b>
                            </Grid>
                            <Grid item style={{ margin: 10 }}>
                              {tableData[idx]["note_commerciali"].map(
                                (note) => (
                                  <small>
                                    {note.dat_inizio} - {note.dat_fine}:{" "}
                                    <b>{note.motivazione}</b>, {note.note}
                                    <br />
                                  </small>
                                )
                              )}
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <DemandRevisionChart
                      id="demand-revision-chart"
                      data={demandChartData}
                    />
                  </>
                )}
                {tableData && !idx && (
                  <p style={{ margin: 20, fontWeight: "bold" }}>
                    Seleziona un codice dalla tabella per visualizzare il
                    grafico
                  </p>
                )}
              </Paper>
            </Grid>
          </Grid>
        </div>
      )}
      {revisionClosed && <RevisionClosed />}
      <CloseRevisionDialog
        open={showConfirmDialog}
        toggleOpen={toggleConfirmDialog}
        openAlert={openAlert}
        counter={counter}
        setTableData={setTableData}
      />
      <SimpleAlert alert={alert} toggleAlert={toggleAlert} />
    </>
  );
}

import React from "react";
import { Route, Redirect } from "react-router-dom";

import AuthenticationService from "../../services/AuthenticationService";

export const PrivateRoute = ({
  component: Component,
  role: Role,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      // Check if the user is logged in
      if (!AuthenticationService.isUserAuthenticated()) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }

      // Check if user can access the component
      const { role } = AuthenticationService.getUserData();
      if(Role && !Role.includes(role)) {
        return (
          <Redirect
            to={{ pathname: "/home", state: { from: props.location } }}
          />
        );
      }

      // The user is authorised so return component
      return <Component {...props} />;
    }}
  />
);

import React, {useState} from "react";

// Service
import ApiService from "../../services/ApiService";

// UI
import MaterialTable from "material-table";
import _ from "lodash";

// Table Params
import { getColumns, OPTIONS, LOCALIZATION } from "./Params/InfoSales";
import { tableIcons } from "./Params/Icons";
import AddInfoDialog from "../Dialogs/AddInfoDialog";

function InfoSalesTable({ data = {}, setData, openAlert, codeList }) {
  const _infoSales = Object.values(data);
  const [showNewInfoDialog, setShowNewInfoDialog] = useState(false)
  const toggleNewInfoDialog = () => setShowNewInfoDialog(!showNewInfoDialog);

  const _onRowUpdate = (newData, oldData) =>
    new Promise((resolve, reject) => {
      let isEqual = true;
      Object.entries(newData).forEach(([key, value]) => {
        if (oldData[key] !== value) {
          isEqual = false;
        }
      });
      setImmediate(() => {
        if (isEqual) {
          resolve();
        } else {
          ApiService.postApi(`input-summary/info-sales/${oldData.id}`, newData)
            .then((response) => {
              setData((prevData) => ({
                ...prevData,
                info_sales: {
                  ...prevData["info_sales"],
                  [response.id]: response,
                },
              }));
              openAlert(
                "success",
                "Informazione commerciale aggiornata con successo!"
              );
              resolve();
            })
            .catch((err) => {
              console.error("Error during user update!", err);
              openAlert(
                "error",
                "Impossibile aggiornare l'informazione commerciale!"
              );
              reject();
            });
        }
      });
    });

  const _onRowDelete = (newData) =>
    new Promise((resolve, reject) => {
      setImmediate(() => {
        ApiService.deleteApi(`input-summary/info-sales/${newData.id}`, newData)
          .then(() => {
            const { [newData.id]: tmp, ...rest } = data;
            setData((prevData) => ({ ...prevData, info_sales: { ...rest } }));
            openAlert("success", "Record eliminato con successo");
            resolve();
          })
          .catch((err) => {
            console.error("Error during user delete!", err);
            openAlert(
              "error",
              "Impossibile eliminare l'informazione commerciale"
            );
            reject();
          });
      });
    });

  return (
    <>
      <MaterialTable
        title="Informazioni commerciali"
        columns={getColumns()}
        data={_infoSales}
        icons={tableIcons}
        isLoading={!_infoSales}
        options={OPTIONS}
        localization={LOCALIZATION}
        editable={{
          onRowUpdate: _onRowUpdate,
          onRowDelete: _onRowDelete,
        }}
        actions={[
            {
              icon: tableIcons.Add,
              onClick: () => {
                toggleNewInfoDialog()
              },
              isFreeAction: true,
              tooltip: 'Aggiungi nota'
            }
          ]}
      />
      <AddInfoDialog
        open={showNewInfoDialog} toggleOpen={toggleNewInfoDialog}
        openAlert={openAlert}
        codeList={codeList}
        setData={setData}
      />
    </>
  );
}

export default React.memo(InfoSalesTable, (props, nextProps) => {
  // Render component only when users changes
  return _.isEqual(props.data, nextProps.data) && _.isEqual(props.codeList, nextProps.codeList);
});

import React from "react";

export default function MakeSelection() {
  return (
    <div style={{ textAlign: "center", marginTop: 50 }}>
      <img
        style={{ width: "25em", height: "auto", marginBottom: "auto" }}
        src={process.env.PUBLIC_URL + "/demand.svg"}
        alt=""
      />
      <h3>Seleziona il filtro per visualizzare il Demand Plan</h3>
    </div>
  );
}

import React from "react";

// UI
import MaterialTable from "material-table";
import _ from "lodash";

// Table Params
import { getColumns, OPTIONS, LOCALIZATION } from "./Params/Stock";
import { tableIcons } from "./Params/Icons";

function StockTable({ data = {}, setData }) {
  const _stock = Object.values(data);

  return (
    <MaterialTable
      title="Stock"
      columns={getColumns()}
      data={_stock}
      icons={tableIcons}
      isLoading={!_stock}
      options={OPTIONS}
      localization={LOCALIZATION}
      editable={{}}
    />
  );
}

export default React.memo(StockTable, (props, nextProps) => {
  // Render component only when users changes
  return _.isEqual(props.data, nextProps.data);
});

import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {ROLES} from "../../../utils/constants";

export const COLUMNS = [
  {
    title: "Username",
    field: "username"
  },
  {
    title: "E-mail",
    field: "email",
  },
  {
    title: "Nome",
    field: "name",
    render: ({ name }) => <b>{name}</b>,
  },
  {
    title: "Cognome",
    field: "surname",
    render: ({ surname }) => <b>{surname}</b>,
  },
  {
    title: "Ruolo",
    field: "role",
    lookup: ROLES.reduce((obj, item) => {return {...obj, [item['value']]: item['label']}}, {}),
    editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => {
      return <Select
        value={p.value}
        onChange={(e) => {
          const newRowData = { ...rowData, role: e.target.value };
          onRowDataChange(newRowData);
        }}>
        {ROLES.map((role) => <MenuItem value={role.value}>{role.label}</MenuItem>)}
      </Select>;
    }
  }
];

export const OPTIONS = {
  filtering: true,
  pageSize: 10,
  searchFieldAlignment: "left",
  searchFieldStyle: {
    marginTop: "2px",
  },
  showTitle: false,
  toolbar: false,
};

export const LOCALIZATION = {
  header: {
    actions: ""
  },
  body: {
    editRow: {
      deleteText: "Eliminare l'utenza?"
    }
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count} utenti",
    labelRowsSelect: "Utenti",
  }
};
import React, { useState } from "react";
import ApiService from "../../services/ApiService";

// UI
import {Button, Dialog, DialogContent, DialogTitle, TextField, Grid} from "@material-ui/core";
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CenteredLoader from "../Loading/CenteredLoader";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import Moment from "moment";


const useStyle = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function AddInfoDialog({open, toggleOpen, openAlert, codeList, setData}) {
  const classes = useStyle();
  const [loading, setLoading] = useState(false);
  const [motivation, setMotivation] = useState(null);
  const [volume, setVolume] = useState(null);
  const [note, setNote] = useState(null);
  const [selectedCode, setSelectedCode] = useState();
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment().add(30,'days'))


  const _onRowAdd = () => {
    setLoading(true)
    const newNote = {
      motivazione: motivation,
      cod_riferimento: selectedCode.cod_riferimento,
      volume_impianto: volume,
      dat_inizio: Moment(startDate).format("YYYY-MM-DD"),
      dat_fine: Moment(endDate).format("YYYY-MM-DD"),
      note: note
    }
    console.log(newNote)
    ApiService.postApi(`input-summary/info-sales/add`, newNote)
      .then((response) => {
        setData((prevData) => ({...prevData, info_sales: {...prevData["info_sales"], [response.id]: response}}));
        openAlert("success", "Informazione commerciale aggiunta con successo!");
      })
      .catch((err) => {
        console.log(err)
        openAlert( "error", "Impossibile aggiungere l'informazione commerciale! Controllare il formato dei dati.");
      })
      .finally(() => {
        toggleOpen()
        setLoading(false)
      })
  }

  function renderOption(option) {
    return (
      <span>
        <b>{option.cod_riferimento}</b> <br />{" "}
        <small>{option['des_materiale']}</small>
      </span>
    );
  }

  function onCodeChange(event, code) {
    if(code) {
      setSelectedCode(code)
    } else {
      setSelectedCode(undefined)
    }
  }

  const handleStartDateChange = (date) => {
    setStartDate(date)
    if (date > endDate) {setEndDate(date)}
  };
  const handleEndDateChange = (date) => {
    setEndDate(date)
    if (date < startDate) {setStartDate(date)}
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={toggleOpen}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Aggiungi nota</DialogTitle>
        <DialogContent style={{minWidth: 550}}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                id="motivation"
                onChange={(e) => {setMotivation(e.target.value)}}
                value={motivation}
                margin="dense"
                label="Motivazione"
                variant="outlined"
                fullWidth
                inputProps={{style: {height: 35}}}
              />
            </Grid>
            <Grid item xs={8}>
              <Autocomplete
                id="member-box"
                value={selectedCode || null}
                options={codeList}
                onChange={onCodeChange}
                getOptionLabel={(option) => option.cod_riferimento}
                renderOption={renderOption}
                renderInput={(params) => (
                  <TextField {...params} label="Codice di riferimento*" variant="outlined" />)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="volume"
                onChange={(e) => {setVolume(e.target.value)}}
                value={volume}
                margin="dense"
                label="Volume impianto"
                type="number"
                variant="outlined"
                fullWidth
                inputProps={{style: {height: 35}}}
                style={{marginTop: 0}}
              />
            </Grid>
            <Grid item style={{marginLeft: "auto", marginRight: "auto"}}>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD/MM/YYYY"
                  id="Da"
                  label="Data inizio*"
                  value={startDate}
                  onChange={handleStartDateChange}
                  style={{marginRight: 50, marginBottom: 5, maxWidth: 140}}
                />
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD/MM/YYYY"
                  id="A"
                  label="Data fine*"
                  value={endDate}
                  onChange={handleEndDateChange}
                  style={{marginBottom: 5, maxWidth: 140}}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="note"
                onChange={(e) => {setNote(e.target.value)}}
                value={note}
                margin="dense"
                label="Note*"
                variant="outlined"
                fullWidth
                inputProps={{style: {height: 40}}}
              />
            </Grid>
          </Grid>
          {loading && <CenteredLoader title={""} />}
          <Grid container>
            <Grid item xs={5}>
              <Button
                onClick={toggleOpen}
                variant="contained"
                className={classes.button}
              >
                Esci
              </Button>
            </Grid>
            <Grid item xs={7} style={{ textAlign: "end" }}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={_onRowAdd}
                disabled={!selectedCode || !note || !startDate || !endDate}
              >
                Aggiungi
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddInfoDialog;

export const getColumns = () => [
  {
    title: "Formato",
    field: "formato",
  },
  {
    title: "Totale Mese (t)",
    field: "val_ton_mese",
    type: 'numeric',
  }
];

export const OPTIONS = {
  filtering: false,
  pageSize: 4,
  showTitle: true,
  search: false,
  paging: false,
};

export const LOCALIZATION = {
  header: {
    actions: ""
  },
  body: {
    editRow: {
      deleteText: "Eliminare l'informazione?"
    }
  },
};

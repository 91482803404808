import React from "react";

// Service
import ApiService from "../../services/ApiService";

// UI
import MaterialTable from 'material-table';
import _ from "lodash";

// Table Params
import {COLUMNS, OPTIONS, LOCALIZATION} from "./Params/Users"
import {tableIcons} from "./Params/Icons";


function UsersTable({ users, setUser, openAlert }) {

  const _users = users &&
  _.values(users).map((user) => ({
    ...user
  }));

  function manageUpdatedUser(oldData, newData) {
    if (oldData.username === newData.username) {
      setUser({ ...users, [newData.username]: newData});
    } else {
      const { [oldData.username]: tmp, ...rest } = users
      setUser({ ...rest, [newData.username]: newData});
    }
  }

  const _onRowUpdate = (newData, oldData) =>
    new Promise((resolve, reject) => {
      let isEqual = true;
      Object.entries(newData).forEach(([key, value]) => {
        if (oldData[key] !== value) {
          isEqual = false
        }
      })
      setImmediate(() => {
        if(isEqual) {
          resolve();
        } else {
          console.log(newData)
          ApiService.postApi(`users/${oldData.username}`, newData)
            .then(() => {
              manageUpdatedUser(oldData, newData);
              openAlert("success", "Utenza aggiornata con successo!");
              resolve();
            })
            .catch((err) => {
              console.error("Error during user update!", err);
              openAlert("error", "Impossibile aggiornare l'utenza!");
              reject();
            })

        }
      })
    });

  const _onRowDelete = (newData) =>
    new Promise((resolve, reject) => {
      setImmediate(() => {
        ApiService.deleteApi(`users/${newData.username}`, newData)
          .then(() => {
            const { [newData.username]: tmp, ...rest } = users
            setUser({ ...rest});
            openAlert("success", "Utenza eliminata con successo");
            resolve();
          })
          .catch((err) => {
            console.error("Error during user delete!", err);
            openAlert("error", "Impossibile eliminare l'utenza");
            reject();
          })
      })
    });

  return (
    <>
      <MaterialTable
        columns={COLUMNS}
        data={_users}
        icons={tableIcons}
        isLoading={_users === undefined}
        options={OPTIONS}
        localization={LOCALIZATION}
        editable={{
          onRowUpdate: _onRowUpdate,
          onRowDelete: _onRowDelete,
        }}
      />
    </>
  );
}

export default React.memo(UsersTable, (props, nextProps) => {
  // Render component only when users changes
  return _.isEqual(props.users, nextProps.users);

})

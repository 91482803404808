import { MTS_MTO } from "../../../utils/constants";

export const getColumns = () => [
  {
    title: "Codice riferimento",
    field: "cod_riferimento",
    render: (rowData) => (
      <>
        <b>{rowData.cod_materiale}</b>
      </>
    ),
  },
  {
    title: "Codice materiale",
    field: "cod_materiale",
  },
  {
    title: "Pianificato (kg)",
    field: "val_pianificato",
    type: "numeric",
  },
  {
    title: "Data",
    field: "dat_pianificato",
    type: "date",
  },
  {
    title: "MTS/MTO",
    field: "stock_order",
    lookup: MTS_MTO.reduce((o, i) => {
      return { ...o, [i]: i };
    }, {}),
  },
  {
    title: "Profit center",
    field: "des_profit_center",
    render: (rowData) => (
      <>
        <small>{rowData.des_profit_center}</small>
      </>
    ),
  },
  {
    title: "Marchio",
    field: "des_marchio",
    render: (rowData) => (
      <>
        <small>{rowData.des_marchio}</small>
      </>
    ),
  },
];

export const OPTIONS = {
  filtering: true,
  pageSizeOptions: [10, 25, 100, 250, 500],
  pageSize: 25,
  headerStyle: { whiteSpace: "nowrap" },
  searchFieldStyle: {
    marginTop: "2px",
  },
  showTitle: true,
};

export const LOCALIZATION = {
  header: {
    actions: "",
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count}",
    labelRowsSelect: "righe",
  },
};

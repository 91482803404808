import React from "react";
import { Link } from "react-router-dom";
import AuthenticationService from "../../../services/AuthenticationService";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";

import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";

import menu from "./menu";

import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingBottom: 4,
    paddingTop: 4,
  },
  listText: {
    color: theme.palette.secondary.dark,
  },
  listIcon: {
    color: theme.palette.primary.main,
  },
  tabTitle: {
    margin: "0.5vw",
    color: theme.palette.secondary.dark,
  },
  listImg: {
    width: "50px",
    marginRight: "2px",
  },
  primary: {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export function List({ to, onClick, icon, text }) {
  const classes = useStyles();
  const actualPath = useLocation().pathname;

  const selected = actualPath === to;

  return (
    <ListItem
      button
      component={Link}
      to={to}
      onClick={onClick}
      selected={selected}
      className={classes.listItem}
    >
      <ListItemIcon className={classes.listIcon}>{icon}</ListItemIcon>
      <ListItemText className={classes.listText} primary={text} />
    </ListItem>
  );
}

export default function Menu({ onClick }) {
  let userRole = null;
  if (AuthenticationService.isUserAuthenticated()) {
    userRole = AuthenticationService.getUserData().role;
  }

  return (
    <>
      {menu.map(({ to, icon, text, divider, role }) =>
        divider ? (
          <Divider
            key={Math.random()}
            style={{ marginTop: 10, marginBottom: 10 }}
          />
        ) : (
          ((role && role.includes(userRole)) || !role) && (
            <List key={to} to={to} onClick={onClick} icon={icon} text={text} />
          )
        )
      )}
    </>
  );
}

export function ListHeader() {
  const classes = useStyles();

  return (
    <>
      <ListItem>
        <ListItemText
          primary={
            <div style={{ display: "flex", verticalAlign: "middle" }}>
              <img
                src={process.env.PUBLIC_URL + "/dematteis.png"}
                className={classes.listImg}
                alt="LOGO"
              />
              <span className={classes.tabTitle}>
                <b>Demand Planning</b>
              </span>
            </div>
          }
        />
      </ListItem>
    </>
  );
}

export function ListUser({ userName, toggleDrawer, logoutHandler }) {
  const classes = useStyles();
  let firstLetter = "";

  if (!userName) {
    userName = "Utente";
  }

  firstLetter = userName
    .toString()
    .trim()
    .split(" ")
    .reduce((letters, string) => {
      console.log(userName);
      console.log(string);
      letters += string[0].toString().toUpperCase();

      return letters;
    }, "");

  return (
    <>
      <ListItem
        component={Link}
        to={"/profile"}
        onClick={toggleDrawer}
        className={classes.listItem}
      >
        <ListItemAvatar>
          <Avatar>{firstLetter}</Avatar>
        </ListItemAvatar>
        <ListItemText className={classes.listText}>
          <b>{userName}</b>
        </ListItemText>
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={logoutHandler}>
            <ExitToAppRoundedIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
}

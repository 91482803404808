import React from "react";
import moment from "moment";
import TextField from "@material-ui/core/TextField";

export function getColumns(weekColumns) {
  let columns = [
    {
      title: "Codice riferimento",
      field: "cod_riferimento",
      render: rowData => <b>{rowData['cod_riferimento']}</b>,
      cellStyle: { paddingTop: 8, paddingBottom: 8, backgroundColor: "white", position: 'sticky', left:0, zIndex: 998 },
      headerStyle: { position: 'sticky', left:0, zIndex: 999},
      editable: "never",
    },
  ];
  if (weekColumns) {
    weekColumns.forEach((week) => {
      columns = columns.concat([
        {
          title: (
            <a>
              <small>SP (Kg)</small>
              <br />
              {moment(week).format("MMM DD")}
            </a>
          ),
          field: week,
          cellStyle: { paddingTop: 3, paddingBottom: 3 },
          headerStyle: { whiteSpace: "nowrap" },
          type: "numeric",
          export: false,
          editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => {
            return (
              <TextField
                type="number"
                size="small"
                inputProps={{ style: { fontSize: 13, width: 70 } }}
                value={p.value}
                onChange={(e) => {
                  const newRowData = {
                    ...rowData,
                    [week]: e.target.value,
                    val_supply_plan:
                      Number(rowData.val_supply_plan) -
                      Number(p.value) +
                      Number(e.target.value),
                  };
                  onRowDataChange(newRowData);
                }}
              />
            );
          },
        },
        {
          title: `SP ${week}`,
          field: week,
          type: "numeric",
          export: true,
          hidden: true,
        },
      ]);
    });
  }

  columns = columns.concat([
    {
      title: (
        <a>
          <small>SP (Kg)</small>
          <br />
          Totale
        </a>
      ),
      field: "val_supply_plan",
      type: "numeric",
      render: rowData => <b>{rowData['val_supply_plan']}</b>,
      cellStyle: { paddingTop: 3, paddingBottom: 3 },
      headerStyle: { whiteSpace: "nowrap" },
      editable: "never",
      export: false,
    },
    {
      title: "Supply Plan (KG)",
      field: "val_supply_plan",
      type: "numeric",
      export: true,
      hidden: true,
    },
    {
      title: "LM (kg)",
      field: "lotto_minimo",
      type: "numeric",
      editable: "never",
    },
    {
      title: "F (%)",
      field: "val_make_flumeri",
      type: "numeric",
      cellStyle: { paddingTop: 3, paddingBottom: 3 },
      headerStyle: { whiteSpace: "nowrap" },
      editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => {
        return (
          <TextField
            type="number"
            size="small"
            inputProps={{ style: { fontSize: 13, width: 50 } }}
            value={p.value}
            onChange={(e) => {
              const target = e.target.value;
              let val_buy = rowData.val_buy;
              let val_giano = rowData.val_make_giano;
              if (+val_buy + +target + +val_giano > 100) {
                val_buy = 100 - +target - +val_giano;
              }
              if (+val_buy + +target + +val_giano > 100) {
                val_giano = 100 - +target - +val_buy;
              }
              const newRowData = {
                ...rowData,
                val_make_flumeri: e.target.value,
                val_make_giano: val_giano,
                val_buy: val_buy,
              };
              onRowDataChange(newRowData);
            }}
          />
        );
      },
    },
    {
      title: "G (%)",
      field: "val_make_giano",
      type: "numeric",
      cellStyle: { paddingTop: 3, paddingBottom: 3 },
      headerStyle: { whiteSpace: "nowrap" },
      editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => {
        return (
          <TextField
            type="number"
            size="small"
            inputProps={{ style: { fontSize: 13, width: 50 } }}
            value={p.value}
            onChange={(e) => {
              const target = e.target.value;
              let val_buy = rowData.val_buy;
              let val_flumeri = rowData.val_make_flumeri;
              if (+val_buy + +target + +val_flumeri > 100) {
                val_buy = 100 - +target - +val_flumeri;
              }
              if (+val_buy + +target + +val_flumeri > 100) {
                val_flumeri = 100 - +target - +val_buy;
              }
              const newRowData = {
                ...rowData,
                val_make_giano: e.target.value,
                val_make_flumeri: val_flumeri,
                val_buy: val_buy,
              };
              onRowDataChange(newRowData);
            }}
          />
        );
      },
    },
    {
      title: "O (%)",
      field: "val_buy",
      type: "numeric",
      cellStyle: { paddingTop: 3, paddingBottom: 3 },
      headerStyle: { whiteSpace: "nowrap" },
      editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => {
        return (
          <TextField
            type="number"
            size="small"
            inputProps={{ style: { fontSize: 13, width: 50 } }}
            value={p.value}
            onChange={(e) => {
              const target = e.target.value;
              let val_giano = rowData.val_make_giano;
              let val_flumeri = rowData.val_make_flumeri;
              if (+target + +val_giano + +val_flumeri > 100) {
                val_giano = 100 - +target - +val_flumeri;
              }
              if (+target + +val_giano + +val_flumeri > 100) {
                val_flumeri = 100 - +target - +val_giano;
              }
              const newRowData = {
                ...rowData,
                val_buy: e.target.value,
                val_make_flumeri: val_flumeri,
                val_make_giano: val_giano,
              };
              onRowDataChange(newRowData);
            }}
          />
        );
      },
    },
    {
      title: "LM",
      field: "flg_lotto_minimo",
      type: "boolean",
      editable: "never",
    },
    {
      title: "Rev.",
      field: "flg_revisionato",
      type: "boolean",
    },
    {
      title: "Info Comm.",
      field: "flg_info_sales",
      type: "boolean",
      editable: "never",
    },
    {
      title: "Stock (kg)",
      field: "val_stock",
      type: "numeric",
      editable: "never",
      export: "true",
    },
  ]);
  return columns;
}

export const OPTIONS = {
  filtering: true,
  paging: true,
  pageSizeOptions: [25, 50, 100, 250, 500],
  pageSize: 50,
  exportButton: true,
  exportDelimiter: ";",
  exportAllData: true,
  exportFileName: "Piano Produzione",
  maxBodyHeight: "700px",
  headerStyle: {
    paddingTop: 6,
    paddingBottom: 6,
    lineHeight: 1.4,
    position: "sticky",
    top: 0,
  },
};

export const LOCALIZATION = {
  header: {
    actions: "",
  },
  body: {
    editRow: {
      deleteText: "Eliminare l'informazione?",
    },
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count}",
    labelRowsSelect: "righe",
  },
};

import React, { useState, useEffect } from "react";

// Service
import ApiService from "../../services/ApiService";

// UI
import MaterialTable from "material-table";
import _ from "lodash";

// Table Params
import { getColumns, OPTIONS, LOCALIZATION } from "./Params/ProductionCapacity";
import { tableIcons } from "./Params/Icons";

function ProductionCapacityTable({
  selection,
  data,
  setData,
  openAlert,
  setDataProductionSummary,
}) {
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    let isAllSelected = false;

    if (selection === "total") {
      isAllSelected = true;
    }

    if (data) {
      setFilteredData(
        _.filter(
          data,
          ({ stabilimento }) => stabilimento === selection || isAllSelected
        )
      );
    }
  }, [selection, data]);

  function manageUpdatedCapacity(oldData, result) {
    setData((prevData) => ({
      ...prevData,
      capacita_produttiva: {
        ...prevData["capacita_produttiva"],
        [result.id]: result,
      },
    }));
    setDataProductionSummary(null);
  }

  const _onRowUpdate = (newData, oldData) =>
    new Promise((resolve, reject) => {
      let isEqual = true;
      Object.entries(newData).forEach(([key, value]) => {
        if (oldData[key] !== value) {
          isEqual = false;
        }
      });
      setImmediate(() => {
        if (isEqual) {
          resolve();
        } else {
          ApiService.postApi(
            `input-summary/capacita-produttiva/${oldData.id}`,
            newData
          )
            .then((result) => {
              manageUpdatedCapacity(oldData, result);
              openAlert("success", "Dati aggiornati con successo!");
              resolve();
            })
            .catch((err) => {
              console.error("Error during user update!", err);
              openAlert("error", "Impossibile aggiornare i dati!");
              reject();
            });
        }
      });
    });

  return (
    <MaterialTable
      title="Capacità produttiva"
      columns={getColumns()}
      data={filteredData}
      icons={tableIcons}
      isLoading={!data}
      options={OPTIONS}
      localization={LOCALIZATION}
      editable={{
        onRowUpdate: _onRowUpdate,
      }}
    />
  );
}

export default React.memo(ProductionCapacityTable, (props, nextProps) => {
  // Render component only when users changes
  return (
    _.isEqual(props.data, nextProps.data) &&
    _.isEqual(props.selection, nextProps.selection)
  );
});

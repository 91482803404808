import React from "react";

function Footer() {
  return (
    <div
      className="w-100 bg-light"
      style={{ position: "absolute", bottom: 5, zIndex: 999, height: "1.5em", display: "flex", justifyContent: "center", width: "100%" }}
    >
      <p style={{ marginTop: "0.5em", fontSize: "10px" }}>
        Powered by &nbsp;
        <a href='https://www.ammagamma.com' target="_blank" rel="noopener noreferrer" style={{textDecoration: "none"}}>
          Ammagamma
        </a>
      </p>
    </div>
  );
}

export default Footer;

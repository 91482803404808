import React from "react";

export default function RevisionClosed() {
  return (
    <div style={{ textAlign: "center", marginTop: 50 }}>
      <img
        style={{ width: "10em", height: "auto", marginBottom: "auto" }}
        src={process.env.PUBLIC_URL + "/close.svg"}
        alt=""
      />
      <p>
        <b>Processo di revisione non ancora disponibile</b>
      </p>
      <p>Attendere l'aggiornamento dati per procedere alla pianificazione</p>
    </div>
  );
}

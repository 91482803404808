import React, { useEffect, useState } from "react";
import { LinearProgress, Typography } from "@material-ui/core";
import ApiService from "../../services/ApiService";
import Grid from "@material-ui/core/Grid";
import MaterialRegistryAssignTable from "../../components/Tables/MaterialRegistryAssignTable";
import MaterialRegistryTable from "../../components/Tables/MaterialRegistryTable";
import AssignMaterialDialog from "../../components/Dialogs/AssignMaterialDialog";
import SimpleAlert from "../../components/Alert/SimpleAlert";
import _ from "lodash";

export default function MaterialRegistry() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [codes, setCodes] = useState();
  const [open, setOpen] = useState(false);
  const [inverse, setInverse] = useState(false);
  const [editRow, setEditRow] = useState(undefined);
  const [listReferenceCodes, setListReferenceCodes] = useState();
  const [alert, setAlert] = useState({ open: false, type: "", message: "" });

  const toggleOpen = () => setOpen(!open);

  const openAssignMaterial = (codes, row, inverse = false) => {
    setCodes(codes);
    if (row) {
      setEditRow(row);
    } else {
      setEditRow(undefined);
    }
    setInverse(inverse);
    toggleOpen();
  };

  const openAlert = (type, message) => {
    setAlert({ open: true, type, message });
  };

  const toggleAlert = () => {
    setAlert({ ...alert, open: !alert.open });
  };

  const fetchData = () => {
    console.log(`fetching data...`);
    setLoading(true);
    ApiService.getApi(`material_registry`)
      .then((result) => {
        setData(result);
        setListReferenceCodes(
          result
            .filter((i) => i.flg_cod_riferimento)
            .map((i) => _.pick(i, ["cod_materiale", "uuid", "des_materiale"]))
        );
      })
      .catch((err) => {
        console.error(`Error when fetching data`, err);
      });
    setLoading(false);
  };

  useEffect(() => {
    if (!data) {
      fetchData();
    }
  }, [data]);

  const resetData = () => {
    setData(undefined);
  };

  const updateToAssignData = () => {
    let data_to_assign = [];
    if (data) {
      data_to_assign = data.filter((i) => i.cod_riferimento === null);
    }
    console.log(`To be assigned ${data_to_assign.length}`);
    ApiService.postApi(`material_registry/update-data-summary`, {
      data_summary: {
        val_codici_assegnare: data_to_assign.length,
      },
    })
      .then((msg) => {
        console.log(msg);
      })
      .catch((err) => {
        console.log(err);
        openAlert("error", "Errore durante l'invio dei dati");
      });
  };

  useEffect(() => {
    updateToAssignData();
  }, [data]);

  console.log(data);

  return (
    <>
      <LinearProgress style={{ visibility: loading ? "visible" : "hidden" }} />
      <Typography variant="h5">Gestione Aggregazione</Typography>
      <hr />
      <Grid container>
        <Grid item xs={5} style={{ marginTop: 10, paddingRight: 40 }}>
          <MaterialRegistryAssignTable
            data={data}
            openAssignMaterial={openAssignMaterial}
          />
        </Grid>
        <Grid item xs={7} style={{ marginTop: 10 }}>
          <MaterialRegistryTable
            data={data}
            openAssignMaterial={openAssignMaterial}
          />
        </Grid>
      </Grid>
      <AssignMaterialDialog
        open={open}
        toggleOpen={toggleOpen}
        openAlert={openAlert}
        codes={codes}
        listReferenceCodes={listReferenceCodes}
        editRow={editRow}
        inverse={inverse}
        resetData={resetData}
        data={data}
      />
      <SimpleAlert alert={alert} toggleAlert={toggleAlert} />
    </>
  );
}

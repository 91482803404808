import React, { useEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import _ from "lodash";
import moment from "moment";

import { COLORS } from "../../utils/constants";

am4core.useTheme(am4themes_animated);

const CHARTS = {
  ordinato: {
    quantity: "ordinato",
    series_1: "val_ordine",
    series_2: "val_previsione",
    label_1: "Ordinato",
    label_2: "Forecast",
  },
  stock: {
    quantity: "stock",
    series_1: "val_stock",
    series_2: "val_stock_revisione",
    label_1: "Stock",
    label_2: "Stock revisionato",
  },
};

function ModelTrendLine(props) {
  const [chartRef, setChartRef] = useState(null);

  useEffect(() => {
    let chart = am4core.create(props.id, am4charts.XYChart);

    let _data = Object.values(
      props.data.reduce((r, o) => {
        const dat_settimana = moment(o.dat_settimana).format("YYYY-MM-DD");
        const weekLabel = moment(o.dat_settimana).format("ww");
        r[dat_settimana] = {
          dat_settimana: dat_settimana,
          weekLabel: weekLabel,
          val_series_1: o[CHARTS[props.quantity].series_1],
          val_series_2: o[CHARTS[props.quantity].series_2],
        };
        return r;
      }, [])
    );

    chart.data = _.sortBy(_data, "dat_settimana");
    chart.logo.disabled = true;
    chart.tapToActivate = false;

    let title = chart.titles.create();
    title.text = props.title;
    title.fontSize = 25;

    // Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 30;
    dateAxis.renderer.labels.template.rotation = -60;
    dateAxis.renderer.labels.template.fontSize = 11;
    dateAxis.renderer.maxLabelPosition = 0.98;

    // Create value axis
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    valueAxis.renderer.minGridDistance = 30;
    valueAxis.renderer.ticks.template.length = 5;
    valueAxis.renderer.ticks.template.disabled = true;
    valueAxis.renderer.ticks.template.strokeOpacity = 0;
    valueAxis.renderer.grid.template.strokeOpacity = 0;
    valueAxis.extraMax = 0.05;

    // Legend
    chart.legend = new am4charts.Legend();
    chart.legend.position = "bottom";
    chart.legend.marginBottom = 10;
    chart.legend.fontSize = 11;
    let markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 11;
    markerTemplate.height = 11;

    // Use only absolute numbers
    chart.numberFormatter.numberFormat = "#,###.";
    chart.language.locale["_thousandSeparator"] = ".";

    // Create series
    function createSeries(field, name, color, dashed) {
      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = field;
      series.dataFields.dateX = "dat_settimana";
      series.name = name;
      series.stroke = color;
      series.fill = color;

      if (dashed) {
        series.strokeDasharray = "5 3";
      }

      // series.bullets.push(new am4charts.CircleBullet());
      let circleBullet = series.bullets.push(new am4charts.CircleBullet());
      circleBullet.circle.stroke = am4core.color("#fff");
      circleBullet.circle.strokeWidth = 1;
      series.tooltipText = "Settimana {dateX}: [bold]{valueY}[/]";
      let hs = series.segments.template.states.create("hover");
      hs.properties.strokeWidth = 5;
      series.segments.template.strokeWidth = 2;

      let scrollbarX = new am4charts.XYChartScrollbar();
      scrollbarX.series.push(series);
      chart.scrollbarX = scrollbarX;
      let scrollAxis = chart.scrollbarX.scrollbarChart.xAxes.getIndex(0);
      scrollAxis.renderer.labels.template.fontSize = 0;

      let range = dateAxis.axisRanges.create();
      range.date = new Date();
      range.grid.stroke = am4core.color("black");
      range.grid.strokeWidth = 2;
      range.grid.strokeOpacity = 1;

      return series;
    }

    createSeries(
      "val_series_2",
      CHARTS[props.quantity].label_2,
      COLORS[CHARTS[props.quantity].series_2]
    );

    createSeries(
      "val_series_1",
      CHARTS[props.quantity].label_1,
      COLORS[CHARTS[props.quantity].series_1]
    );

    // Add chart cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "zoomX";

    // Pre-zoom the chart
    chart.events.on("ready", function () {
      let now = new Date();
      dateAxis.zoomToDates(
        new Date(now.getFullYear(), now.getMonth() - 2, 1),
        new Date(now.getFullYear(), now.getMonth() + 3, 1)
      );
    });

    valueAxis.title.text = "Quantità (kg)";
    valueAxis.title.fontWeight = "bold";

    chart.exporting.title = "dematteis";
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.align = "left";
    chart.exporting.menu.verticalAlign = "top";

    setChartRef(chart);

    return () => {
      chart.dispose();
    };
  }, [props.data, props.id]);

  if (chartRef) {
    console.log("Created chart with id:", chartRef.baseId);
  }

  return (
    <div id={props.id} style={{ minWidth: "500px", minHeight: "400px" }} />
  );
}

export default React.memo(ModelTrendLine, (props, nextProps) => {
  // Render component only when users changes
  return _.isEqual(props.data, nextProps.data);
});

import './App.css';
import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { PrivateRoute } from "./components/PrivateRoute";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import AppNavigation from "./components/AppNavigation";

import Login from "./containers/Login";
import Home from "./containers/Home";
import Profile from "./containers/Profile";
import Users from "./containers/Users";
import DemandRevision from "./containers/DemandRevision";
import MaterialRegistry from "./containers/MaterialRegistry";
import VisualizzazioneModelli from "./containers/ForecastVisualization";
import InputSummary from "./containers/InputSummary";
import MakeOrBuy from './containers/MakeOrBuy';
import SupplyPlan from './containers/SupplyPlan';


const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: "80px",
    maxWidth: "1600px"
  },
}));

function App() {
  const classes = useStyles();

  return (
    <>
      <Router>
        <PrivateRoute
          exact
          path="/"
          component={() => <Redirect to={{ pathname: "/home" }} />}
        />
        <Route path="/login" component={Login} />

        <Route
          path={
            [
              "/home",
              "/profile",
              "/users",
              "/gestione-aggregazione",
              "/visualizzazione-modelli",
              "/input-summary",
              "/revisione-domanda",
              "/make-or-buy",
              "/supply-plan",
            ]
          }
          render={({ location, history }) => (
            <React.Fragment>
              <AppNavigation history={history}/>
              <Container maxWidth="lg" className={classes.main}>
                <PrivateRoute exact path="/home" component={Home}/>
                <PrivateRoute exact path="/profile" component={Profile}/>
                <PrivateRoute exact path="/users" component={Users} role={["admin"]}/>
                <PrivateRoute exact path="/gestione-aggregazione" component={MaterialRegistry} role={["admin"]}/>
                <PrivateRoute exact path="/visualizzazione-modelli" component={VisualizzazioneModelli} role={["admin", "commerciale"]}/>
                <PrivateRoute exact path="/input-summary" component={InputSummary} role={["admin", "commerciale"]}/>
                <PrivateRoute exact path="/revisione-domanda" component={DemandRevision} role={["admin"]}/>
                <PrivateRoute exact path="/make-or-buy" component={MakeOrBuy} role={["admin"]}/>
                <PrivateRoute exact path="/supply-plan" component={SupplyPlan} role={["admin"]}/>
              </Container>
            </React.Fragment>
          )}
        />
      </Router>
      </>
  );
}

export default App;

import React from "react";
import _ from "lodash";
import {tableIcons} from "./Params/Icons";
import MaterialTable from 'material-table';
import {COLUMNS, OPTIONS, LOCALIZATION} from "./Params/MaterialRegistry"


function MaterialRegistryTable({ data, openAssignMaterial }) {

  return (
    <>
      <MaterialTable
        columns={COLUMNS}
        data={data}
        icons={tableIcons}
        isLoading={data === undefined}
        options={OPTIONS}
        localization={LOCALIZATION}
        title={"Anagrafica materiali"}
        actions={[
          {icon: tableIcons.Edit, tooltip: 'Modifica assegnazione', onClick: (event, rowData) => openAssignMaterial([rowData.cod_materiale], rowData)}
        ]}
      />
    </>
  );
}

export default React.memo(MaterialRegistryTable, (props, nextProps) => {
  // Render component only when users changes
  return _.isEqual(props.data, nextProps.data);

})

import React  from 'react';

// Services
import ApiService from "../../services/ApiService";

// UI
import { Button, Dialog, DialogContent, DialogTitle, Divider, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import makeStyles from "@material-ui/core/styles/makeStyles";

// Utils
import { useFormik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import {ROLES} from "../../utils/constants";


const useStyle = makeStyles(theme => ({
    button: {
      margin: theme.spacing(3, 0, 2),
    }
}))

const USER_INIT_STATE = {
  name: "",
  surname: "",
  email: "",
  username: "",
  role: ""
};

const FIELD_VALIDATION_REQUIRED_LABEL = "Campo richiesto!";
const FIELD_VALIDATION_EMAIL_LABEL = "Il campo deve essere una email!";

function AddUserDialog({
  // Dialog options
  open, toggleOpen,

  // Alert options
  openAlert,

  // State and setting
  users, setUsers,
}) {

  const classes = useStyle();
  const schema = Yup.object({
    name: Yup.string().required(FIELD_VALIDATION_REQUIRED_LABEL),
    surname: Yup.string().required(FIELD_VALIDATION_REQUIRED_LABEL),
    email: Yup.string().email(FIELD_VALIDATION_EMAIL_LABEL).required(FIELD_VALIDATION_REQUIRED_LABEL),
    username: Yup.string().required(FIELD_VALIDATION_REQUIRED_LABEL)
      .min(5, "Inserire almeno 5 caratteri").max(25, "Inserire massimo 25 caratteri")
      .test("not contain dots", "Non inserire punti o spazi", function (value) {return !value || (!value.includes('.') && !value.includes(' '))}),
    role: Yup.string().required(FIELD_VALIDATION_REQUIRED_LABEL)
  });

  const _onSubmit = (user, {resetForm}) => {
    console.log("Submit");

    ApiService.postApi("users", user)
      .then((data) => {
        console.log(data);
        setUsers({
          ...users,
          [data.username]: data,
        });
        resetForm({});
        toggleOpen();
        openAlert("success", "Utente creato con successo!");
      })
      .catch((err) => {
        console.log(err)
        openAlert("error", "Username e/o E-mail già in uso!");
      })
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    values,
    errors,
  } = useFormik({
    initialValues: USER_INIT_STATE,
    validationSchema: schema,
    onSubmit: _onSubmit,
  });

  const isFieldValidationError = (name) =>
    touched[name] && errors[name];

  const getFieldError = (name) =>
    isFieldValidationError(name)
      ? errors[name]
      : "";

  return (
    <div>
      <Dialog open={open} onClose={toggleOpen} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Aggiungi utenza</DialogTitle>
        <DialogContent>
          <form id="add-user-form" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  id="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={_.get(values, "name")}
                  margin="dense"
                  label="Nome"
                  name="name"
                  type="text"
                  variant="outlined"
                  fullWidth
                  required
                  error={isFieldValidationError("name")}
                  helperText={getFieldError("name")}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="surname"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={_.get(values, "surname")}
                  margin="dense"
                  label="Cognome"
                  name="surname"
                  type="text"
                  variant="outlined"
                  fullWidth
                  required
                  error={isFieldValidationError("surname")}
                  helperText={getFieldError("surname")}
                />
              </Grid>
              <Divider />
              <Grid item xs={12}>
                <TextField
                  id="username"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={_.get(values, "username")}
                  margin="dense"
                  label="Username"
                  name="username"
                  type="text"
                  variant="outlined"
                  fullWidth
                  required
                  error={isFieldValidationError("username")}
                  helperText={getFieldError("username")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={_.get(values, "email")}
                  margin="dense"
                  label="E-mail"
                  name="email"
                  type="text"
                  variant="outlined"
                  fullWidth
                  required
                  error={isFieldValidationError("email")}
                  helperText={getFieldError("email")}
                />
              </Grid>

              <Divider variant="middle" />

              <Grid item xs={12}>
                <InputLabel id="user-role">Ruolo</InputLabel>
                <Select
                  labelId="user-role"
                  id="demo-simple-select"
                  name="role"
                  value={_.get(values, "role")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  required
                  error={isFieldValidationError("role")}
                  helperText={getFieldError("role")}
                >
                  {ROLES.map((userRole) =>
                    <MenuItem key={userRole.value} value={userRole.value}>{userRole.label}</MenuItem>
                  )}
                </Select>
              </Grid>
            </Grid>
          </form>
          <Grid container>
            <Grid item xs={5}>
              <Button onClick={toggleOpen} variant="contained" className={classes.button}>
                Esci
              </Button>
            </Grid>
            <Grid item xs={7} style={{textAlign: "end"}}>
              <Button
                form="add-user-form"
                type="submit"
                color="primary"
                variant="contained"
                className={classes.button}
                disabled={!_.get(values, "role")}
              >
                Aggiungi
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}


export default AddUserDialog;
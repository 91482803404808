export const getColumns = () => [
  {
    title: "Motivazione",
    field: "motivazione",
  },
  {
    title: "Codice riferimento",
    field: "cod_riferimento",
  },
  {
    title: "Volume impianto",
    field: "volume_impianto",
  },
  {
    title: "Da",
    field: "dat_inizio",
    type: "date",
    defaultSort: "desc",
  },
  {
    title: "A",
    field: "dat_fine",
    type: "date",
  },
  {
    title: "Note",
    field: "note",
    cellStyle: { minWidth: 250 },
    headerStyle: { minWidth: 250 },
  },
];

export const OPTIONS = {
  filtering: true,
  pageSize: 10,
  searchFieldStyle: {
    marginTop: "2px",
  },
  showTitle: true,
};

export const LOCALIZATION = {
  header: {
    actions: "",
  },
  body: {
    editRow: {
      deleteText: "Eliminare l'informazione?",
    },
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count} info commerciali",
    labelRowsSelect: "righe",
  },
};

export const getColumns = () => [
  {
    title: "Linea",
    field: "linea",
    editable: false,
  },
  {
    title: "Formato",
    field: "formato",
    editable: false,
  },
  {
    title: "ton_h",
    field: "val_ton_h",
    type: "numeric",
  },
  {
    title: "Giorni",
    field: "val_giorni",
    type: "numeric",
  },
  {
    title: "Efficienza (%)",
    field: "val_efficienza",
    type: "numeric",
  },
  {
    title: "Totale Mese (t)",
    field: "val_ton_mese",
    type: "numeric",
    editable: false,
  },
];

export const OPTIONS = {
  filtering: false,
  paging: false,
  search: false,
  headerStyle: { whiteSpace: "nowrap" },
  pageSize: 10,
  searchFieldStyle: {
    marginTop: "2px",
  },
  showTitle: true,
};

export const LOCALIZATION = {
  header: {
    actions: "",
  },
  body: {
    editRow: {
      deleteText: "Eliminare l'informazione?",
    },
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count} info commerciali",
    labelRowsSelect: "righe",
  },
};

import React from "react";
import MaterialTable from "material-table";
import { tableIcons } from "./Params/Icons";
import {getColumns, OPTIONS, LOCALIZATION,} from "./Params/ProductionCapacitySummary";

function ProductionCapacitySummaryTable({ selection, data }) {
  let _data;
  if (data) {
    _data = data.result;
  }

  const PLANTS = {
    flumeri: "Flumeri",
    giano: "Giano",
    outsourcing: "Outsourcing",
    total: "Totale",
  };

  return (
    <MaterialTable
      title={`Summary ${PLANTS[selection]}`}
      columns={getColumns()}
      data={_data}
      icons={tableIcons}
      isLoading={!_data}
      options={OPTIONS}
      localization={LOCALIZATION}
    />
  );
}

export default ProductionCapacitySummaryTable;

import React, { useCallback, useEffect, useState } from "react";
import { LinearProgress, TextField, Typography, Grid } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import _ from "lodash";

import { FILTER_DIMENSION } from "../../utils/constants";
import ModelTrendLine from "../../components/Charts/ModelTrendLine";
import ApiService from "../../services/ApiService";

const EXTRA_DIMENSION = [
  { value: "total", label: "Consuntivo" },
  { value: "cod_riferimento", label: "Prodotto" },
];

const useStyles = makeStyles(() => ({
  grid: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  formControl: {
    width: 300,
    marginTop: -15,
    marginBottom: 25,
  },
  selection: {
    width: 350,
    marginTop: 15,
    marginBottom: 10,
  },
}));

export default function ForecastVisualization() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [period, setPeriod] = useState("");
  const [codeList, setCodeList] = useState();
  const [totalAggregationForecast, setTotalAggregationForecast] = useState();
  const [totalAggregationStock, setTotalAggregationStock] = useState();
  const [selectedDimension, setSelectedDimension] = useState(
    EXTRA_DIMENSION[0]
  );
  const [valueOptions, setValueOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState();
  const [dataForecast, setDataForecast] = useState();
  const [dataStock, setDataStock] = useState();

  const fetchCodeListAndTotal = () => {
    console.log(`fetching products...`);
    setLoading(true);
    ApiService.getApi(`forecast`)
      .then((result) => {
        setCodeList(result["codes"]);
        setTotalAggregationForecast(result["total_aggregation_forecast"]);
        setTotalAggregationStock(result["total_aggregation_stock"]);
        setPeriod(result["period"]);
      })
      .catch((err) => {
        console.error(`Error when fetching products`, err);
      })
      .finally(() => {setLoading(false)})
  };

  const fetchOrders = useCallback(() => {
    console.log(`fetching orders...`);
    setLoading(true);
    const dimension = selectedDimension.value;
    const value =
      selectedDimension.value === "cod_riferimento"
        ? selectedValue.cod_riferimento
        : selectedValue.replace("/", "");
    ApiService.getApi(`forecast/${dimension}/${value}`)
      .then((result) => {
        setDataForecast(result["forecast"]);
        setDataStock(result["stock"]);
        setLoading(false);
      })
      .catch((err) => {
        console.error(`Error when fetching orders`, err);
        setLoading(false);
      });
  }, [selectedDimension, selectedValue]);

  useEffect(() => {
    if (!codeList) {
      fetchCodeListAndTotal();
    }
  }, [codeList]);

  useEffect(() => {
    if (selectedValue) {
      fetchOrders();
    }
  }, [selectedValue, fetchOrders]);

  function onDimensionChange(event, selection) {
    setSelectedValue(null);
    setDataForecast(null);
    setDataStock(null);
    setSelectedDimension(selection);
    if (!selection || selection.value === "total") {
      setValueOptions([]);
    } else if (selection.value !== "cod_riferimento") {
      setValueOptions(_.uniq(codeList.map((i) => i[selection.value])));
    } else {
      setValueOptions(codeList);
    }
  }

  function onValueChange(event, selection) {
    setSelectedValue(selection);
  }

  function getValueRenderOption(option) {
    if (selectedDimension.value !== "cod_riferimento") {
      return option;
    }
    return (
      <span>
        <b>{option["cod_riferimento"]}</b>
        <br />
        <small>{option["des_materiale"]}</small>
      </span>
    );
  }

  function getValueOptionLabel(option) {
    if (selectedDimension.value !== "cod_riferimento") {
      return option;
    }
    return option.cod_riferimento;
  }

  const Title = () => (
    <Grid container style={{ marginBottom: "10px" }}>
      <Grid item xs={6}>
        <Typography variant="h5"> Visualizzazione Modelli</Typography>
      </Grid>
      <Grid item xs={6}>
        <Grid container justify="flex-end">
          {period && (
            <Typography variant="h6">
              Periodo di riferimento: <b>{period}</b>
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      <LinearProgress style={{ visibility: loading ? "visible" : "hidden" }} />
      <Title />
      <hr />
      {codeList && (
        <Grid container spacing={3}>
          <Grid item className={classes.grid}>
            <Autocomplete
              id="dimension"
              className={classes.selection}
              value={selectedDimension || null}
              options={EXTRA_DIMENSION.concat(FILTER_DIMENSION)}
              getOptionLabel={(i) => i.label}
              renderOption={(i) => i.label}
              onChange={onDimensionChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Analizza per:"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item className={classes.grid}>
            <Autocomplete
              id="value"
              className={classes.selection}
              options={valueOptions}
              getOptionLabel={getValueOptionLabel}
              renderOption={getValueRenderOption}
              value={selectedValue || null}
              onChange={onValueChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    selectedDimension && selectedDimension.value !== "total"
                      ? selectedDimension.label
                      : "-"
                  }
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grid>
      )}
      <Grid container>
        <Grid item xs={12}>
          {totalAggregationForecast &&
            selectedDimension &&
            selectedDimension.value === "total" && (
              <ModelTrendLine
                id="forecast-orders-trend-line"
                data={totalAggregationForecast}
                title="Ordini"
                quantity="ordinato"
              />
            )}
          {dataForecast && selectedDimension.value !== "total" && (
            <ModelTrendLine
              id="forecast-orders-trend-line"
              data={dataForecast}
              title="Ordini"
              quantity="ordinato"
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {totalAggregationStock &&
            selectedDimension &&
            selectedDimension.value === "total" && (
              <ModelTrendLine
                id="forecast-stock-trend-line"
                data={totalAggregationStock}
                title="Stock"
                quantity="stock"
              />
            )}
          {dataStock && selectedDimension.value !== "total" && (
            <ModelTrendLine
              id="forecast-stock-trend-line"
              data={dataStock}
              title="Stock"
              quantity="stock"
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

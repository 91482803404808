import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";

// Services
import ApiService from "../../services/ApiService";
import UsersTable from "../../components/Tables/UsersTable";
import SimpleAlert from "../../components/Alert/SimpleAlert";
import AddUserDialog from "../../components/Dialogs/AddUserDialog";

// UI
import { LinearProgress, Button, Grid, Link, Typography } from "@material-ui/core";
import { ArrowBackIos, AddCircle } from "@material-ui/icons";


export default function Users() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({open: false, type: "", message: ""});
  const [users, setUsers] = useState();
  const [showNewUserDialog, setShowNewUserDialog] = useState(false)

  const fetchUsers = () => {
    console.log("fetching...");
    setLoading(true)
    ApiService.getApi('users')
      .then((users) => {
        setUsers(users.reduce((usersObj, user) =>
            _.set(usersObj, user.username, user),
            {}
          ));
      })
      .catch((err) => {
        console.error("Error when fetching Users:", err);
      })
    setLoading(false)
  }

  useEffect(() => {
    if(!users) {
      fetchUsers();
    }
  }, [users]);

  if(users) {
    console.log("Rendered Users:", users);
  }

  const openAlert = (type, message) => {
    setAlert({
      open: true,
      type,
      message,
    })
  }

  const toggleAlert = () => {
    setAlert({
      ...alert,
      open: !alert.open,
    })
  }

  const toggleNewUserDialog = () => setShowNewUserDialog(!showNewUserDialog);

  const Title = () =>
    <Grid container style={{marginBottom: "10px"}}>
      <Grid item xs={6}>
        <Typography variant="h4" display="inline" gutterBottom>
          <Link onClick={() => history.goBack()}>
            <ArrowBackIos />
          </Link>
          Utenze
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Grid container justify="flex-end">
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircle />}
            onClick={toggleNewUserDialog}
          >
            Aggiungi
          </Button>
        </Grid>
      </Grid>
    </Grid>
  return (
    <>
      <LinearProgress style={{visibility: loading ? 'visible' : 'hidden'}}/>
      <Title />
      <UsersTable users={users} setUser={setUsers} openAlert={openAlert} />
      <AddUserDialog
        open={showNewUserDialog} toggleOpen={toggleNewUserDialog}
        openAlert={openAlert}
        users={users}
        setUsers={setUsers}
      />
      <SimpleAlert alert={alert} toggleAlert={toggleAlert} />
    </>
  );

}


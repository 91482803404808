import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FILTER_DIMENSION } from "../../utils/constants";

import ApiService from "../../services/ApiService";
import SupplyPlanBarChart from "../../components/Charts/SupplyPlanBarChart";
import MakeSelection from "../../components/Alert/MakeSelection";

export default function SupplyPlan() {
  const [filterCategory, setFilterCategory] = useState();
  const [data, setData] = useState();

  function onDimensionChange(event, category) {
    setFilterCategory(category);
  }

  const fetchData = () => {
    console.log(`fetching orders...`);
    ApiService.getApi(`supply-plan/${filterCategory.value}`)
      .then((result) => {
        setData(result);
      })
      .catch((err) => {
        console.error(`Error when fetching orders`, err);
      });
  };

  useEffect(() => {
    if (filterCategory) {
      fetchData();
    }
  }, [filterCategory]);

  return (
    <>
      <Typography variant="h5"> Supply Plan</Typography>
      <hr />
      <Autocomplete
        id="dimension"
        style={{ width: 250, margin: 12 }}
        value={filterCategory || null}
        options={FILTER_DIMENSION}
        getOptionLabel={(i) => i.label}
        renderOption={(i) => i.label}
        onChange={onDimensionChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Filtra per:"
            variant="outlined"
            size="small"
          />
        )}
      />

      {!filterCategory && <MakeSelection />}

      {filterCategory && data && (
        <SupplyPlanBarChart
          id="supply-plan-chart"
          data={data}
          category={filterCategory}
        />
      )}
    </>
  );
}

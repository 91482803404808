import React, { useState } from "react";

import Appbar from "./Appbar";
import Appdrawer from "./Appdrawer";

export default function AppNavigation({ history, changeGlobalGroup }) {
  const [state, setState] = useState({
    drawerOpen: false,
  });

  const toggleDrawer = () => setState({ drawerOpen: !state.drawerOpen });
  const changeGroup = (g) => {changeGlobalGroup(g)}

  return (
    <>
      <Appbar toggleDrawer={toggleDrawer} changeGroup={changeGroup} />
      <Appdrawer
        drawerOpen={state.drawerOpen}
        toggleDrawer={toggleDrawer}
        history={history}
      />
    </>
  );
}

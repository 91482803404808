import React from "react";

export const COLUMNS = [
  {
    title: "Codice materiale",
    field: "cod_materiale",
    cellStyle: { padding: 1 },
  },
  {
    title: "Descrizione",
    field: "des_materiale",
    cellStyle: { paddingTop: 1, paddingBottom: 1, paddingLeft: 5, paddingRight: 5, minWidth: 150  },
    render: (rowData) => (
      <>
        <small>{rowData.des_materiale}</small>
      </>
    ),
  },
  {
    title: "Stato",
    field: "cod_stato_materiale",
    cellStyle: { padding: 1 },
  },
];

export const OPTIONS = {
  filtering: true,
  selection: true,
  pageSize: 10,
  search: false,
  showTextRowsSelected: false,
  actionsColumnIndex: -1,
  headerStyle: { padding: 1 },
  filterCellStyle: {
    paddingLeft: 1,
    paddingRight: 1,
    paddingBottom: 5,
    paddingTop: 2,
  },
};

export const LOCALIZATION = {
  header: {
    actions: "",
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count}",
    labelRowsSelect: "",
  },
};

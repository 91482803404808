import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  appBar: {
    color: "#212121",
    backgroundColor: "#fafafa",
  },
  imgTitle: {
    height: "50px",
    marginTop: "-5px",
    marginRight: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    marginTop: "0.5vw",
    display: "flex",
    verticalAlign: "middle",
    color: theme.palette.secondary.dark
  },
  label: {
    fontSize: '0.7rem'
  }
}));

export default function Appbar({ toggleDrawer }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <AppBar className={classes.appBar} position="fixed">
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>

            <div className={classes.title}>
              <Link to={"/home"}>
                <img
                  className={classes.imgTitle}
                  style={{width: "5em", height: "auto", marginTop: 'auto', marginBottom: 'auto'}}
                  src={process.env.PUBLIC_URL + "/dematteis.png"}
                  alt="LOGO"
                />
              </Link>
              <span style={{marginTop: "1.5vh"}}><b>De Matteis Demand Planning</b></span>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    </>
  );
}

import React from "react";

// Service
import ApiService from "../../services/ApiService";

// UI
import MaterialTable from "material-table";
import _ from "lodash";

// Table Params
import { getColumns, OPTIONS, LOCALIZATION } from "./Params/MakeOrBuy";
import { tableIcons } from "./Params/Icons";

function MakeOrBuyTable({
  filteredCodes,
  tableData,
  setTableData,
  setPlantChartData,
  setIdx,
  weekColumns,
  openAlert,
  handleChangeData,
  setLoading,
}) {
  const _makeBuyData = filteredCodes ? filteredCodes : [];

  function manageUpdateTableData(changes) {
    console.log(changes);
    const tmp = { ...tableData };
    changes.forEach((i) => {
      i['flg_lotto_minimo'] = i['val_supply_plan'] > i['lotto_minimo']
      tmp[i.uuid] = i;
    });
    setTableData(tmp);
    setPlantChartData(null);
    setIdx(null);
  }

  const _onBulkUpdate = (changes) =>
    new Promise((resolve, reject) => {
      setLoading(true);
      const data = Object.keys(changes).map((k) => changes[k]["newData"]);
      setImmediate(() => {
        ApiService.postApi(`make-or-buy`, data)
          .then(() => {
            manageUpdateTableData(data);
            openAlert("success", "Aggiornato con successo!");
            resolve();
          })
          .catch((err) => {
            console.error("Error during make-or-buy update!", err);
            openAlert("error", "Impossibile aggiornare!");
            reject();
          })
          .finally(() => {
            setLoading(false);
          });
      });
    });

  return (
    <MaterialTable
      title={"Distribuzione Supply Plan"}
      columns={getColumns(weekColumns)}
      data={_makeBuyData}
      icons={tableIcons}
      isLoading={!_makeBuyData}
      options={OPTIONS}
      localization={LOCALIZATION}
      editable={{
        onBulkUpdate: _onBulkUpdate,
      }}
      actions={[{ icon: "", hidden: true }]} // necessary to avoid a bug
      onRowClick={handleChangeData}
    />
  );
}

export default React.memo(MakeOrBuyTable, (props, nextProps) => {
  return (
    _.isEqual(props.filteredCodes, nextProps.filteredCodes) &&
    _.isEqual(props.weekColumns, nextProps.weekColumns)
  );
});

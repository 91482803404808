import { createMuiTheme } from '@material-ui/core/styles';

// Color import
import { blueGrey } from '@material-ui/core/colors';

const theme = createMuiTheme({
  palette: {
    background: {
      default: "#fefbf2"
    },
    primary: {
      light: blueGrey[500],
      main: blueGrey[600],
      dark: blueGrey[700],
      contrastText: '#fff',
    },
    secondary: {
      light: blueGrey[500],
      main: blueGrey[600],
      dark: blueGrey[700],
      contrastText: '#fff',
    },
  },
});

export default theme;
